import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, addDoc, collection, arrayUnion, updateDoc, getDoc } from "firebase/firestore";
import { CardElement } from "@stripe/react-stripe-js";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../../../shared/firebase";
import QRCode from 'qrcode';

const getCsrfToken = () => {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta && meta.getAttribute("content");
};

const validatePassword = (password) => {
  return password.length >= 8;
};

export const handleSubmit = async (
  event,
  stripe,
  elements,
  formData,
  setError,
  setIsSubmitting,
) => {
  const isLoggedIn = !!auth.currentUser.uid;

  console.log("handleSubmit called with isLoggedIn:", isLoggedIn);
  event.preventDefault();
  setError(null);
  setIsSubmitting(true);

  if (!stripe || !elements) {
    setIsSubmitting(false);
    return;
  }

  if (!isLoggedIn) {
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setIsSubmitting(false);
      return;
    }

    if (!validatePassword(formData.password)) {
      setError("Password must be at least 8 characters long");
      setIsSubmitting(false);
      return;
    }
  }

  const cardElement = elements.getElement(CardElement);

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
  });

  const success_url = "/payments/success";

  if (error) {
    setError(error.message);
    setIsSubmitting(false);
  } else {
    // Fetch the existing user document
    const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
    const userData = userDoc.data();

    const formDataToSend = {
      stripe_customer_id: isLoggedIn ? userData?.stripeCustomerId : null,
      payment_method_id: paymentMethod.id,
      ...formData,
      return_url: window.location.origin + success_url,
      email: auth.currentUser?.email,
    };
    console.log("Form data being sent:", formDataToSend);

    try {
      const response = await fetch("/payments/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCsrfToken(),
        },
        body: JSON.stringify(formDataToSend),
      });

      const paymentResult = await response.json();
      console.log("Full payment result:", paymentResult);

      // Check if customer_id exists
      if (paymentResult.customer_id) {
        console.log("Stripe customer ID received:", paymentResult.customer_id);
      } else {
        console.warn("No Stripe customer ID in the payment result");
      }

      if (paymentResult.error) {
        setError(paymentResult.error);
        setIsSubmitting(false);
      } else if (paymentResult.requires_action) {
        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(paymentResult.client_secret);
        if (confirmError) {
          setError(confirmError.message);
          setIsSubmitting(false);
        } else {
          console.log("Payment successful, calling handlePaymentSuccess");
          handlePaymentSuccess(formData, setError, setIsSubmitting, isLoggedIn, paymentResult);
        }
      } else {
        handlePaymentSuccess(formData, setError, setIsSubmitting, isLoggedIn, paymentResult);
      }
    } catch (fetchError) {
      setError(`Fetch error: ${fetchError.message}`);
      setIsSubmitting(false);
    }
  }
};

const handlePaymentSuccess = async (formData, setError, setIsSubmitting, isLoggedIn, paymentResult) => {
  console.log("handlePaymentSuccess called with isLoggedIn:", isLoggedIn);
  try {
    let userId;
    // Retrieve questionnaireData early
    const questionnaireData = JSON.parse(localStorage.getItem("questionnaireData"));
    console.log("Questionnaire data:", questionnaireData);

    // Retrieve Stripe customer ID from the payment result
    const stripeCustomerId = paymentResult?.customer_id;
    console.log("Stripe customer ID:", stripeCustomerId);

    if (isLoggedIn) {
      userId = auth.currentUser.uid;
      console.log("User already logged in, userId:", userId);

      // Fetch the existing user document
      const userDoc = await getDoc(doc(db, "users", userId));
      const userData = userDoc.data();

      // Check if the user already has a Stripe customer ID
      if (userData.stripeCustomerId) {
        console.log("User already has a Stripe customer ID:", userData.stripeCustomerId);
      } else if (stripeCustomerId) {
        // Update existing user with Stripe customer ID if it doesn't exist
        await updateDoc(doc(db, "users", userId), {
          stripeCustomerId: stripeCustomerId,
          updatedAt: new Date(),
        });
        console.log("Updated user with new Stripe customer ID:", stripeCustomerId);
      } else {
        console.warn("Stripe customer ID not found in payment result");
      }
    } else {
      console.log("Creating new user with email:", formData.email);
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      userId = userCredential.user.uid;
      console.log("New user created, userId:", userId);

      console.log("Adding user data to Firestore");
      const userData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        location: localStorage.getItem("userCountry"),
        subscriptionLevel: 1,
        recipients: arrayUnion({
          relationship: questionnaireData?.relationship,
          firstName: formData.recipientFirstName,
          lastName: formData.recipientLastName,
          email: formData.recipientEmail,
          sendGiftOn: formData.sendGiftOn,
          hobbiesInterests: questionnaireData?.hobbiesInterests,
          favoriteMemory: questionnaireData?.favoriteMemory,
        }),
        orderDetails: {
          personalizedUsb: formData.personalizedUsb,
          personalizedVinyl: formData.personalizedVinyl,
        },
        updatedAt: new Date(),
        createdAt: new Date(),
      };

      // Only add stripeCustomerId if it exists
      if (stripeCustomerId) {
        userData.stripeCustomerId = stripeCustomerId;
      }

      await setDoc(doc(db, "users", userId), userData);
      console.log("User data added to Firestore");
    }

    console.log("Adding recipient to Firestore");
    const recipientUserId = await addRecipientToFirestore({
      firstName: formData.recipientFirstName,
      lastName: formData.recipientLastName,
      location: localStorage.getItem("userCountry"),
      email: formData.recipientEmail,
      isGiftee: true,
      giftDetails: {
        relationship: questionnaireData.relationship,
        hobbiesInterests: questionnaireData.hobbiesInterests,
        favoriteMemory: questionnaireData.favoriteMemory,
      },
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    console.log("Recipient added to Firestore, recipientUserId:", recipientUserId);

    if (questionnaireData && userId && recipientUserId) {
      let imageUrl = questionnaireData.uploadedImage;

      // Upload image if it's a data URL
      if (imageUrl && imageUrl.startsWith('data:image')) {
        try {
          imageUrl = await uploadImageAndGetURL(imageUrl, userId);
          console.log("Image uploaded successfully:", imageUrl);
        } catch (uploadError) {
          console.error("Failed to upload image:", uploadError);
          imageUrl = null;
        }
      }

      const albumData = {
        title: questionnaireData.cardTitle,
        tracks: questionnaireData.generatedQuestions.map(q => ({ title: q.text, createdAt: new Date() })),
        userId: userId,
        sharedWith: [recipientUserId],
        privacyLevel: 1,
        createdAt: new Date(),
        updatedAt: new Date(),
        imageUrl: imageUrl,
        cardMessage: questionnaireData.cardMessage,
        senderClosing: questionnaireData.senderClosing,
      };

      console.log("Album data to be saved:", albumData); // Add this log

      try {
        const albumRef = await addDoc(collection(db, "albums"), albumData);
        console.log("Album created with ID:", albumRef.id);
        let albumId = albumRef.id;

        // Generate and upload QR code
        const qrCodeUrl = await generateAndUploadQRCode(albumId, userId);
        console.log("QR code uploaded successfully:", qrCodeUrl);

        // Update album with QR code URL
        await updateDoc(doc(db, "albums", albumId), {
          qrCodeUrl: qrCodeUrl
        });
        console.log("Album updated with QR code URL");

        console.log("Sending invite email");
        if (albumId && recipientUserId) {
          try {
            const response = await fetch("/payments/send_invite", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getCsrfToken(),
              },
              body: JSON.stringify({
                recipient_email: formData.recipientEmail,
                first_name: formData.firstName,
                last_name: formData.lastName,
                send_gift_on: formData.sendGiftOn,
                album_id: albumId,
                recipient_user_id: recipientUserId,
                card_title: questionnaireData.cardTitle,
                card_message: questionnaireData.cardMessage,
                sender_closing: questionnaireData.senderClosing,
                qr_code_url: qrCodeUrl,
                question1: questionnaireData.generatedQuestions[0].text,
                question2: questionnaireData.generatedQuestions[1].text,
                question3: questionnaireData.generatedQuestions[2].text,
                image_url: (imageUrl && imageUrl.startsWith('http')) ? imageUrl : "https://media.cleanshot.cloud/media/39638/EqTfKzYIW6XE7D5BPycNcGWaEDFljKrGspQjzlVu.jpeg?Expires=1726182226&Signature=XX-z7OeX6I-W7RFbGufsorKXcakHHWDxNT7GmAUhEfdytiV6EQb4eaXip9wSZ0rNUQ~aLfwPhHC36hjexN~EbuAGW4NPeytfj6MKucP75Lbgox42uoB8nC61sopl1Qru3981fZKJ7Z90b2MQ6sKDIkM0q-v~adrgprl9RE47TSyI~SRfin5N9m4bWJS4IP-k6CrvQIM2dXh6lfjfeuvQ71O~Prn2exb3u67LSbtY-DfPAbe6KUMhx5vGLsYLPuOKz44n9WtxLNUTY2G9MhX9pv-S-rHk~nt-axejgsxE-3bd9JwptbvCWsCSPbwtT13eXCHc6zlBz1pnlDusXwntMg__&Key-Pair-Id=K269JMAT9ZF4GZ",
              }),
            });

            if (!response.ok) {
              throw new Error('Failed to send invite email');
            }

            console.log("Invite email sent successfully");
          } catch (error) {
            console.error("Error sending invite email:", error);
          }
        }

        console.log("Redirecting to success page");
        window.location.href = "/payments/success";
      } catch (error) {
        console.error("Error creating album or generating QR code:", error);
        setError(`An error occurred while creating the album or generating QR code. Please contact support. Error: ${error.message}`);
        setIsSubmitting(false);
      }
    } else {
      console.error("Missing data for album creation:", { questionnaireData, userId, recipientUserId });
      setError("Missing data for album creation. Please try again or contact support.");
      setIsSubmitting(false);
    }
  } catch (error) {
    console.error("Error in handleSuccess:", error);
    setError(`An error occurred during checkout. Please contact support. Error: ${error.message}`);
    setIsSubmitting(false);
  }
};

// New function to add recipient data to Firestore
const addRecipientToFirestore = async (recipientData) => {
  console.log("Adding recipient to Firestore:", recipientData);
  try {
    const recipientDocRef = await addDoc(collection(db, "users"), recipientData);
    console.log("Recipient added to Firestore with ID:", recipientDocRef.id);
    return recipientDocRef.id;
  } catch (error) {
    console.error("Error adding recipient to Firestore:", error);
    throw error;
  }
};

const uploadImageAndGetURL = async (imageDataUrl, userId) => {
  try {
    const imageRef = ref(storage, `album_photos/${userId}/${Date.now()}.jpg`);
    await uploadString(imageRef, imageDataUrl, 'data_url');
    return await getDownloadURL(imageRef);
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

// New function to generate and upload QR code
const generateAndUploadQRCode = async (albumId, userId) => {
  try {
    const url = `https://app.goldenrecord.app/album/${albumId}`;
    const qrCodeDataUrl = await QRCode.toDataURL(url, { type: 'image/jpeg', quality: 0.92 });
    const qrCodeRef = ref(storage, `qr_codes/${userId}/${albumId}.jpg`);
    await uploadString(qrCodeRef, qrCodeDataUrl, 'data_url');
    return await getDownloadURL(qrCodeRef);
  } catch (error) {
    console.error("Error generating or uploading QR code:", error);
    throw error;
  }
};
